.image {
    border-radius: 5px;
    transition: border-radius 0.1s;
}

#Card .description{
    visibility: hidden;
    height: 0;
    max-height: 0px;
    opacity: 0;
    transition: max-height 1.5s ease-out, visibility 0s linear 300ms, opacity 0s;
}

#Card:hover .description{
    visibility: visible;
    transition: max-height 0.5s ease-in, visibility 0s linear 0.1s, opacity 0.15s;
    height: auto;
    max-height: 900px;
    opacity: 1;
    padding: 5px;
    background-color: white;
    border-radius: 0 0 5px 5px;
}

#Card:hover .image{
    transition: border-radius 0.1s;
    border-radius: 5px 5px 0 0;
}
